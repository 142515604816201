import React, { useState, useEffect } from 'react';
import { IconButton, Popper, Paper, Typography, List, ListItem, ListItemIcon } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';

const servicesToCheck = [
  { name: 'E-ticket', url: 'https://queuetest.softtouch.eu/status' },
  { name: 'SoftTouch Main Site', url: 'https://softtouch.eu' },
  { name: 'SoftTouch Status Page', url: 'https://status.softtouch.eu' },
  { name: 'Auth API', url: 'status' },
];

const checkInterval = 300000; // 5 minutes in milliseconds

const ServiceStatus = props => {
  const [unavailableServices, setUnavailableServices] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const checkServices = async () => {
      const unavailable = [];

      for (const service of servicesToCheck) {
        if (service.url.startsWith('http')) {
          try {
            const response = await axios.head(service.url);
            if (response.status !== 200 && response.status !== 304 ) {
              unavailable.push(service.name);
            }
          } catch (error) {
            unavailable.push(service.name);
          }
        } else if (service.url === 'status') {
          try {
            const response = await axios.post(SftGlobalData.baseURL_API+service.url, {key: props.api}, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }});
                
                if(SftGlobalData.debug){
                    console.log('url', SftGlobalData.baseURL_API+service.url);
                    console.log('response', response);
                }
                if (response.status === 200 || response.status === 304) {
                    unavailable.push(...response.data.map(entry => `${entry.name}`));
                } else {
                    unavailable.push(service.name);
                }
            
          } catch (error) {
            unavailable.push(service.name);
          }
        }
      }

      setUnavailableServices(unavailable);
    };

    checkServices();

    const intervalId = setInterval(checkServices, checkInterval);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      {unavailableServices.length > 0 && (
        <>
          <IconButton color="warning" onClick={handleClick}>
            <WarningIcon fontSize="large" />
          </IconButton>
          <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 9999 }}>
            <Paper elevation={3} style={{ padding: '10px' }}>
              <Typography variant="h6" gutterBottom className='error_text bold' style={{textAlign: "center"}}>
                <a className='error_text bold' target="_BLANK" href="https://status.softtouch.eu">Unavailable Services</a>
              </Typography>
              <List>
                {unavailableServices.map((service, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                        <WarningIcon color='error' />
                    </ListItemIcon>
                    {service}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Popper>
        </>
      )}
    </div>
  );
};

export default ServiceStatus;

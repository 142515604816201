import React from 'react';


const HomeScreen = (props) =>  {

    //---------------------------------------------------------------------------------------------
    //    OUTPUT
    //---------------------------------------------------------------------------------------------

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <h2 style={{textAlign: 'center'}}>{props.t('welcome')}</h2>
        </div>
    );
}

export default HomeScreen;

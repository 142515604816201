import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import TextField from '@mui/material/TextField';
import EmailEditor from 'react-email-editor';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';
import MySoftTouch from '../assets/application-icons/ic_mysft.png';
import MySales from '../assets/application-icons/ic_mysales.png';
import MyFashionManager from '../assets/licence-icons/office.png';
import Consultation from '../assets/mobile-icons/ic_consultation.png';
import Collection from '../assets/licence-icons/collection.png';
import Cloud from '../assets/licence-icons/cloud.png';
import MyFasmanMobile from '../assets/licence-icons/mobile.png';
import MyFasmanRevenue from '../assets/application-icons/ic_revenue.png';
import Webshop from '../assets/mobile-icons/ic_webshop.png';
import Integrations from '../assets/licence-icons/integrations.png';
import Hardware from '../assets/licence-icons/hardware.png';
import Others from '../assets/licence-icons/other.png';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormControlLabel, InputLabel, Select, Switch } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonIcon from '@mui/icons-material/Person';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import FloatingBackButton from '../components/FloatingBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const ManualEditScreen = (props) =>  {  
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [applications, setApplications] = useState([]);
    const [template, setTemplate] = useState('0');
    const [templates, setTemplates] = useState([]);
    const [applyTemplateLoading, setApplyTemplateLoading] = useState(false);

    const [selectedApp, setSelectedApp] = useState(12);
    const [visibility, setVisibility] = useState("INTERNAL");
    const [type, setType] = useState("MANUAL");
    const [tags, setTags] = useState("");
    const [active, setActive] = useState(false);

    const [titleNL, setTitleNL] = useState("");
    const [titleFR, setTitleFR] = useState("");
    const [titleEN, setTitleEN] = useState("");

    const [htmlNL, setHtmlNL] = useState("<div></div>");
    const [htmlFR, setHtmlFR] = useState("<div></div>");
    const [htmlEN, setHtmlEN] = useState("<div></div>");

    const [designNL, setDesignNL] = useState(null);
    const [designFR, setDesignFR] = useState(null);
    const [designEN, setDesignEN] = useState(null);

    const [approvedNL, setApprovedNL] = useState(false);
    const [approvedFR, setApprovedFR] = useState(false);
    const [approvedEN, setApprovedEN] = useState(false);
    
    const [idNL, setIdNL] = useState(0);
    const [idFR, setIdFR] = useState(0);
    const [idEN, setIdEN] = useState(0);

    let params = useParams();
    let navigate = useNavigate();
    const emailEditorRefNL = useRef(null);
    const emailEditorRefFR = useRef(null);
    const emailEditorRefEN = useRef(null);

    useEffect(()=>{
        props.changeScreen('logging');
        if(params.id !== "+"){ // UPDATE EXISTING FLOW
            let manualId = params.id + "";

            let url = SftGlobalData.baseURL_API + 'docs&action=fetchManual';
            let postData = {
                sft_id: props.login.sft_id,
                sft_group: props.login.sft_group, 
                login: props.login.login,
                id: manualId
                };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('PostData', postData);
                    console.log('response', response);
                }
                setSelectedApp(response.data.application_id)
                setTitleNL(response.data.title_nl);
                setTitleEN(response.data.title_en);
                setTitleFR(response.data.title_fr);
                setTags(response.data.tag);
                setActive(response.data.active === "1");
                setType(response.data.type);
                if(response.data.type === "VERSION") setTemplate("1");
                setVisibility(response.data.visibility);
                
                response.data.detail.forEach(element => {
                    switch(element.language){
                        case "NL":
                            setHtmlNL(element.html);
                            setIdNL(element.id);
                            setApprovedNL(element.approved_by !== "0");
                            try{setDesignNL(JSON.parse(element.design));
                            try{
                                if(element.design){
                                    emailEditorRefNL.current.loadDesign(JSON.parse(element.design));
                                }
                            }catch(e){
                            }}catch(e){}
                            break;
                        case "FR":
                            setHtmlFR(element.html);
                            setIdFR(element.id);
                            setApprovedFR(element.approved_by !== "0");
                            try{setDesignFR(JSON.parse(element.design));
                            try{
                                if(element.design){
                                    emailEditorRefFR.current.loadDesign(JSON.parse(element.design));
                                }
                            }catch(e){
                            }}catch(e){}
                            break;
                        case "EN":
                            setHtmlEN(element.html);
                            setIdEN(element.id);
                            setApprovedEN(element.approved_by !== "0");
                            try{setDesignEN(JSON.parse(element.design));
                            try{
                                if(element.design){
                                    emailEditorRefEN.current.loadDesign(JSON.parse(element.design));
                                }
                            }catch(e){
                            }}catch(e){}
                            break;
                        default:
                            break;
                    }
                });
                let url3 = SftGlobalData.baseURL_API + 'docs&action=fetchApplications';

                axios.post(url3, 
                    {
                        key: props.api, 
                        sft_id: props.login.sft_status,
                        sft_group: props.login.sft_group,
                        login: props.login.login            
                    }, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    }).then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url3);
                            console.log('response', response);
                        }            
                        setApplications(response.data);
                        let urlTemplates = SftGlobalData.baseURL_API + 'templates&action=fetchHeaders';
                        let postDataTemplates = {
                            sft_id: props.login.sft_status,
                            sft_group: props.login.sft_group,
                            login: props.login.login,
                            key: props.api,
                            type: "DOCS",
                            active: 1
                        }
                        axios.post(urlTemplates, postDataTemplates, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }})
                        .then(resp => {
                            if(SftGlobalData.debug){
                                console.log('url', urlTemplates);
                                console.log('response', resp);
                            }
                            setTemplates(resp.data);
                            setLoading(false);
                        });
                        
                });
            });
        } else { // CREATE NEW FLOW
            let url3 = SftGlobalData.baseURL_API + 'docs&action=fetchApplications';

            axios.post(url3, 
                {
                    sft_id: props.login.sft_status,
                    sft_group: props.login.sft_group,
                    login: props.login.login            
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url3);
                        console.log('response', response);
                    }            
                    setApplications(response.data);
                    let urlTemplates = SftGlobalData.baseURL_API + 'templates&action=fetchHeaders';
                    let postDataTemplates = {
                        sft_id: props.login.sft_status,
                        sft_group: props.login.sft_group,
                        login: props.login.login,
                        key: props.api,
                        type: "DOCS",
                        active: 1
                    }
                    axios.post(urlTemplates, postDataTemplates, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }})
                    .then(resp => {
                        if(SftGlobalData.debug){
                            console.log('url', urlTemplates);
                            console.log('response', resp);
                        }
                        setTemplates(resp.data);
                        setLoading(false);
                    });
            });
        }
    }, [props.api, params.id, props.login]);


    const applyTemplateHandler = () => {
        setApplyTemplateLoading(true);
        let url = SftGlobalData.baseURL_API + 'templates&action=fetchTemplate';
        let postData = {
            sft_id: props.login.sft_status,
            sft_group: props.login.sft_group,
            login: props.login.login,
            key: props.api,
            one_lang: false,
            id: template
        }
        axios.post(url, postData, {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }})
        .then(resp => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('postData', postData);
                console.log('response', resp);
            }
            //setDesign(JSON.parse(resp.data.design));
            //setHtml(resp.data.html);
            if(resp.data.detail.length > 0){
                resp.data.detail.forEach(element => {
                    switch(element.language) {
                        case "NL":
                            setDesignNL(JSON.parse(element.design));
                            setHtmlNL(element.html);
                            try{emailEditorRefNL.current.loadDesign(JSON.parse(element.design));}catch(e){}
                            break;
                        case "FR":
                            setDesignFR(JSON.parse(element.design));
                            setHtmlFR(element.html);
                            try{emailEditorRefFR.current.loadDesign(JSON.parse(element.design));}catch(e){}
                            break;
                        case "EN":
                            setDesignEN(JSON.parse(element.design));
                            setHtmlEN(element.html);
                            try{emailEditorRefEN.current.loadDesign(JSON.parse(element.design));}catch(e){}
                            break;
                        default:
                            break;
                    }
                });
            }
            
            setApplyTemplateLoading(false);
        });
        
    }


    const onSave = () => {
        exportHtml();
    }

    const exportHtml = () => {
        let design_temp  = "";
        let html_temp    = "";
        try{
            emailEditorRefFR.current.editor.exportHtml((data) => {
                const { design, html } = data;
                setHtmlFR(html);
                setDesignFR(design);
                design_temp = JSON.stringify(design);
                html_temp = html;
                if(params.id !== "+"){ // SAVE
                    updateManual(design_temp, html_temp, value);
                } else {
                    storeManual("FR", design_temp, html_temp);
                }
            });
        }catch(e){

        }
        try{
            emailEditorRefEN.current.editor.exportHtml((data) => {
                const { design, html } = data;
                setHtmlEN(html);
                setDesignEN(design);
                design_temp = JSON.stringify(design);
                html_temp = html;
                if(params.id !== "+"){ // SAVE
                    updateManual(design_temp, html_temp, value);
                } else {
                    storeManual("EN", design_temp, html_temp);
                }
            });
        }catch(e){

        }
        try{
            emailEditorRefNL.current.editor.exportHtml((data) => {
              const { design, html } = data;
              setHtmlNL(html);
              setDesignNL(design);
              //console.log("design", JSON.stringify(design));
              design_temp = JSON.stringify(design);
              html_temp = html;
              if(params.id !== "+"){ // SAVE
                updateManual(design_temp, html_temp, value);
              } else {
                storeManual("NL", design_temp, html_temp);
              }
            });
        }catch(e){

        }


    }

    function getApplicationIcon(names){
        switch (names){
            case "1":
            case "MySales": 
                return MySales;
            case "2":
            case "MyFashionManager": 
                return MyFashionManager;
            case "3":
            case "Consultation": 
                return Consultation;
            case "4":
            case "Collection": 
                return Collection;
            case "5":
            case "Cloud": 
                return Cloud;
            case "6":
            case "MyFasman Mobile": 
                return MyFasmanMobile;
            case "7":
            case "MyFasman Revenue": 
                return MyFasmanRevenue;
            case "8":
            case "Webshop": 
                return Webshop;
            case "9":
            case "Integrations": 
                return Integrations;
            case "10":
            case "Hardware": 
                return Hardware;
            case "11":
            case "MySoftTouch":
                return MySoftTouch;
            default:
            case "12":
            case "Others": 
                return Others;
        }
    }

    const updateManual = (design, html, id) => {
        if(titleNL === "" || titleFR === "" || titleEN === ""){
            props.notify(props.t('notify_title_fields_required'), 7500, "top-right", props.theme, 8797); 
        } else {
            let manualId = params.id + "";
            //console.log("App id", selectedApp);
            //console.log("DESIGN", design);c
            let url = SftGlobalData.baseURL_API + 'docs&action=update';
            let postData = {
                sft_id: props.login.sft_status,
                sft_group: props.login.sft_group, 
                login: props.login.login,
                id: manualId,

                application_id: selectedApp,
                visibility: visibility,
                type: type,
                active: active,
                tag: tags,
                title_nl: titleNL,
                title_fr: titleFR,
                title_en: titleEN, 

                /*detail_id: (id === 0 ? idNL : (id === 1 ? idFR : idEN)),
                html: html,
                design: design,
                approved: (id === 0 ? approvedNL : (id === 1 ? approvedFR : approvedEN)),*/

                detail_nl_id: idNL,
                detail_fr_id: idFR,
                detail_en_id: idEN,

                nl_html: id === 0 ? html : htmlNL,
                fr_html: id === 1 ? html : htmlFR,
                en_html: id === 2 ? html : htmlEN,

                nl_design: id === 0 ? design : JSON.stringify(designNL),
                fr_design: id === 1 ? design : JSON.stringify(designFR),
                en_design: id === 2 ? design : JSON.stringify(designEN),

                nl_approved: approvedNL,
                fr_approved: approvedFR,
                en_approved: approvedEN

                };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);
                setLoading(false);
            });
        }
    }

    const storeManual = (lang, design, html) => {
        //console.log("App id", selectedApp);
        //console.log("DESIGN", design);
        if(titleNL === "" || titleFR === "" || titleEN === ""){
            props.notify(props.t('notify_title_fields_required'), 7500, "top-right", props.theme, 8797); 
        } else {
            let url = SftGlobalData.baseURL_API + 'docs&action=store';
            let postData = {
                sft_id: props.login.sft_status,
                sft_group: props.login.sft_group, 
                login: props.login.login,

                application_id: selectedApp,
                active: active,
                tag: tags, 
                type: type,
                visibility: visibility,

                title_nl: titleNL,
                title_fr: titleFR,
                title_en: titleEN, 

                html_nl: lang === "NL" ? html : htmlNL,
                html_fr: lang === "FR" ? html : htmlFR,
                html_en: lang === "EN" ? html : htmlEN,

                design_nl: lang === "NL" ? design : JSON.stringify(designNL),
                design_fr: lang === "FR" ? design : JSON.stringify(designFR),
                design_en: lang === "EN" ? design : JSON.stringify(designEN),

                approved_nl: approvedNL,
                approved_fr: approvedFR,
                approved_en: approvedEN

                };
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log('url', url);
                    console.log('postData', postData);
                    console.log('response', response);
                }
                props.notify(props.t('savedChanges'), 2000, "top-right", props.theme, 1);
                navigate("/"+props.lang+"/manuals/edit/"+response.data.id);
            });
        }
    }

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        // emailEditorRef.current.editor.loadDesign(templateJson);
    }

    const onReady = () => {
        // editor is ready
        //console.log('design', designNL);
        try{
            if(designNL){
                emailEditorRefNL.current.loadDesign(designNL);
            }
        }catch(e){
            console.error(e);
        }

        try{
            if(designFR){
                emailEditorRefFR.current.loadDesign(designFR);
            }
        }catch(e){
        }

        try{
            if(designEN){
                emailEditorRefEN.current.loadDesign(designEN);
            }
        }catch(e){
        }
        console.log('onReady');
    }


    const handleChange = (event, newValue) => {
        try{
            switch(value){
                case 0: // NL
                    emailEditorRefNL.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        console.log("design", typeof design !== "undefined");
                        if(typeof design !== "undefined"){
                            setHtmlNL(html);
                            setDesignNL(design);
                        }
                        setValue(newValue);
                      });
                    break;
                case 1: // FR
                    emailEditorRefFR.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlFR(html);
                            setDesignFR(design);
                        }
                        setValue(newValue);
                    });
                    break;
                case 2: // EN
                    emailEditorRefEN.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlEN(html);
                            setDesignEN(design);
                        }
                        setValue(newValue);
                    });
                    break;
                default:
                    break;
            }
        }catch(e){
            //console.error(e);
            setValue(newValue);
        }
    };

    const getApplicationOptions = applications.map((item) => (
        <MenuItem key={item.id} value={item.id} style={{listStyleType: 'none'}}>
            <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="23"
                  height="23"
                  src={getApplicationIcon(item.id)}
                  alt={item.name}
                />
                {item.name}
            </Box>
        </MenuItem>
    ));


    const copyDesign = (orig, target) => {
        try{
            switch(value){
                case 0: // NL
                    emailEditorRefNL.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        console.log("design", typeof design !== "undefined");
                        if(typeof design !== "undefined"){
                            setHtmlNL(html);
                            setDesignNL(design);
                            if(target === "FR"){
                                setHtmlFR(html);
                                setDesignFR(design);
                            } else if(target === "EN"){
                                setHtmlEN(html);
                                setDesignEN(design);
                            }
                        }
                      });
                    break;
                case 1: // FR
                    emailEditorRefFR.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlFR(html);
                            setDesignFR(design);
                            if(target === "NL"){
                                setHtmlNL(html);
                                setDesignNL(design);
                            } else if(target === "EN"){
                                setHtmlEN(html);
                                setDesignEN(design);
                            }
                        }
                    });
                    break;
                case 2: // EN
                    emailEditorRefEN.current.editor.exportHtml((data) => {
                        const { design, html } = data;
                        if(typeof design !== "undefined"){
                            setHtmlEN(html);
                            setDesignEN(design);
                            if(target === "FR"){
                                setHtmlFR(html);
                                setDesignFR(design);
                            } else if(target === "NL"){
                                setHtmlNL(html);
                                setDesignNL(design);
                            }
                        }
                    });
                    break;
                default:
                    break;
            }
        }catch(e){
            console.error(e);
        }
    }

    return (
        <div style={{height: 'fit-content'}} className={(props.screen === "logging" ? ('log__margin_sidebar log__container_content _80perc ' + props.theme + ' container_content_' + props.theme + '  box_fade-in') : (props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme)} >
            <FloatingBackButton disabled={false} onClick={()=>{navigate("/"+props.lang+"/manuals");}} />
            <FloatingSaveButton disabled={loading} onClick={()=>{onSave();}} />
            <div className={"container_list__title " + props.theme}><h2>Manual - {params.id === "+" ? props.t('add') : props.t('edit')}</h2></div>
            <br/><br/>
            {loading && <div style={{display:"flex", justifyContent: "space-evenly", marginTop: 10}}>
                <TailSpin 
                    height="40"
                    width="40"
                    color={Colors.sft_blue}
                    ariaLabel='loading'
                />
            </div>}
            {!loading && 
            <div>
                <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                    <TextField
                        className={props.theme+'_accent shipping_field_full_width margin_right__10px'}
                        id="titleNL"
                        label={props.t('title_translation')+" NL"}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        value={titleNL}            
                        onChange={(event)=>{setTitleNL(event.target.value)}}
                        />

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" style={{color: Colors.sft_blue}}>{props.t('application')}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={props.theme+'_accent shipping_field_full_width '}
                        value={selectedApp}
                        label={props.t('application')}
                        size={'small'}
                        style={{marginBottom:10, borderRadius: 6, height: 43}}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        onChange={(event)=>{setSelectedApp(event.target.value);}}
                        MenuProps={MenuProps}
                      >
                        {getApplicationOptions}
                      </Select>
                    </FormControl>
                </div>
                <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                    <TextField
                        className={props.theme+'_accent shipping_field_full_width margin_right__10px'}
                        id="titleFR"
                        label={props.t('title_translation') + " FR"}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        value={titleFR}            
                        onChange={(event)=>{setTitleFR(event.target.value)}}
                        />
                    
                    <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" style={{color: Colors.sft_blue}}>{props.t('visibility')}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        className={props.theme+'_accent shipping_field_full_width '}
                        id="demo-simple-select"
                        value={visibility}
                        label={props.t('visibility')}
                        size={'small'}
                        style={{marginBottom:10, borderRadius: 6, height: 43}}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        onChange={(event)=>{setVisibility(event.target.value);}}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={"CUSTOMER"}>
                            <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <PersonIcon style={{color: Colors.sft_blue, marginRight: 5}}/>
                                {props.t('CUSTOMER')}
                            </Box>
                        </MenuItem>
                        <MenuItem value={"DEV"}>
                            <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <IntegrationInstructionsIcon style={{color: Colors.sft_blue, marginRight: 5}}/>
                                {props.t('DEV')}
                            </Box>
                        </MenuItem>
                        <MenuItem value={"INTERNAL"}>
                            <Box style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <SupportAgentIcon style={{color: Colors.sft_blue, marginRight: 5}}/>
                                {props.t('INTERNAL')}
                            </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                </div>
                <div className={"log__container_options log__100_perc padding_left__5px padding_right__5px"}>
                    <TextField
                        className={props.theme+'_accent shipping_field_full_width margin_right__10px'}
                        id="titleEN"
                        label={props.t('title_translation')+" EN"}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        value={titleEN}            
                        onChange={(event)=>{setTitleEN(event.target.value)}}
                        />
                    <TextField
                        className={props.theme+'_accent shipping_field_full_width'}
                        id="tags"
                        label={props.t('tags')}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.sft_blue
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.sft_blue
                                }}}
                        value={tags}            
                        onChange={(event)=>{setTags(event.target.value)}}
                        />
                </div>
                {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && <div className={"log__container_options  log__100_perc padding_left__5px padding_right__5px"}>
                    {(props.login.sft_status !== "3") && <div className={"log__container_options log__100_perc "} style={{marginBottom: 0}}>
                        

                            <FormControl className={props.theme+ '_accent _100perc margin_right__10px'}>
                              <InputLabel id="demo-simple-select-label">Template</InputLabel>
                              <Select
                                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={template}
                                label="Template"
                                onChange={(event)=>{const {target: { value },} = event; setTemplate(value); if(value === "1") setType("VERSION"); else setType("MANUAL");}}
                                style={{height: 40, paddingTop: 8, paddingBottom: 8}}>
                                    <MenuItem
                                    key={0}
                                    value={0}
                                    >
                                        CUSTOM
                                    </MenuItem>
                                {templates.map((template) => (
                                    <MenuItem
                                    key={template.id}
                                    value={template.id}
                                    >
                                        {template.title}
                                    </MenuItem>
                                ))}
                                
                              </Select>
                            </FormControl>
                            <LoadingButton 
                                loading={applyTemplateLoading}
                                type="button" 
                                className='login__button width_140px'
                                size="large"
                                onClick={()=>{applyTemplateHandler()}}>&nbsp;{props.t('apply')}&nbsp;</LoadingButton>

                        
                        </div>}
                    <FormControlLabel
                          sx={{textAlign: 'left', marginRight: 1, width: '99%'}}
                          control={
                            <Switch
                              checked={active}
                              onChange={()=>{setActive(!active);}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          labelPlacement="start"
                          label={props.t('active')}
                        />
                </div>}

                {
                    /****************************************************************************
                     * 
                     *                             HTML SECTION
                     * 
                     ****************************************************************************/
                }


            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={props.theme + ' container_tabs_below_subtitle'}>
              <Tabs value={value} onChange={handleChange} className={props.theme} variant="scrollable" scrollButtons="auto">
                <Tab label={"NL "} {...a11yProps(0)} className={props.theme}/>
                <Tab label={"FR "} {...a11yProps(1)} className={props.theme}/>                
                <Tab label={"EN "} {...a11yProps(2)} className={props.theme}/>
              </Tabs>
            </Box>
          
              <TabPanel value={value} index={0}>
                {!loading && <div style={{height: 750, width: '100%', marginTop: 3 }}>
                    {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0, marginRight: 10}} onClick={()=>{if (window.confirm("Copy design? (NL -> FR)") === true) {copyDesign('NL', 'FR');}}}>&nbsp;NL -&gt; FR&nbsp;</Button>}
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0}} onClick={()=>{if (window.confirm("Copy design? (NL -> EN)") === true) {copyDesign('NL', 'EN');}}}>&nbsp;NL -&gt; EN&nbsp;</Button>}
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") &&  <FormControlLabel
                          sx={{textAlign: 'left', marginLeft: 0, marginBottom: 1, width: '80%'}}
                          control={
                            <Switch
                              checked={approvedNL}
                              onChange={()=>{setApprovedNL(!approvedNL);}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          labelPlacement="start"
                          label={props.t('approved')}
                        />}
                        {(props.login.sft_status !== "3") && <EmailEditor
                            minHeight={700}
                            ref={emailEditorRefNL}
                            onLoad={onLoad}
                            onReady={onReady}
                            appearance={
                                {
                                    theme: props.theme,
                                    panels: {
                                      tools: {
                                        dock: 'right'
                                      }
                                    }
                                }
                            }
                            options={{
                                displayMode: "web", 
                                fonts: {
                                    customFonts: [
                                        {
                                            label: "Urbane Rounded",
                                            value: "'Urbane Rounded',cursive",
                                            url: "https://my.softtouch.eu/files/fonts/stylesheet.css",
                                            weights: [{label: 'Regular', value: 600}]
                                        }
                                    ]
                                }
                               
                            }}
                        />}
                        {props.login.sft_status === "3" && <div style={{height: "fit-content"}} dangerouslySetInnerHTML={{__html: htmlNL}}></div>}
                    </div>}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {!loading && <div style={{height: 750, width: '100%', marginTop: 3 }}>
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0, marginRight: 10}} onClick={()=>{if (window.confirm("Copy design? (FR -> NL)") === true) {copyDesign('FR', 'NL');}}}>&nbsp;FR -&gt; NL&nbsp;</Button>}
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0}} onClick={()=>{if (window.confirm("Copy design? (FR -> EN)") === true) {copyDesign('FR', 'EN');}}}>&nbsp;FR -&gt; EN&nbsp;</Button>}
                    {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && <FormControlLabel
                          sx={{textAlign: 'left', marginLeft: 0, marginBottom: 1, width: (props.login.sft_status !== "3" ? '80%' : '100%')}}
                          control={
                            <Switch
                              checked={approvedFR}
                              onChange={()=>{setApprovedFR(!approvedFR);}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          labelPlacement="start"
                          label={props.t('approved')}
                        />}
                        <EmailEditor
                            minHeight={700}
                            ref={emailEditorRefFR}
                            onLoad={onLoad}
                            onReady={onReady}
                            appearance={
                                {
                                    theme: props.theme,
                                    panels: {
                                      tools: {
                                        dock: 'right'
                                      }
                                    }
                                }
                            }
                            options={{
                                displayMode: "web", 
                                fonts: {
                                    customFonts: [
                                        {
                                            label: "Urbane Rounded",
                                            value: "'Urbane Rounded',cursive",
                                            url: "https://my.softtouch.eu/files/fonts/stylesheet.css",
                                            weights: [{label: 'Regular', value: 600}]
                                        }
                                    ]
                                }
                               
                            }}
                        />
                    </div>}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {!loading && <div style={{height: 750, width: '100%', marginTop: 3 }}>
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0, marginRight: 10}} onClick={()=>{if (window.confirm("Copy design? (EN -> NL)") === true) {copyDesign('EN', 'NL');}}}>&nbsp;EN -&gt; NL&nbsp;</Button>}
                    {(props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && 
                    <Button type="button" className="button_container__button width_150px"  style={{marginBottom: 0}} onClick={()=>{if (window.confirm("Copy design? (EN -> FR)") === true) {copyDesign('EN', 'FR');}}}>&nbsp;EN -&gt; FR&nbsp;</Button>}
                    {((props.login.sft_group === "ADMIN" || props.login.sft_group === "SUPER") && props.login.sft_status !== "3") && <FormControlLabel
                          sx={{textAlign: 'left', marginLeft: 0, marginBottom: 1, width: '80%'}}
                          control={
                            <Switch
                              checked={approvedEN}
                              onChange={()=>{setApprovedEN(!approvedEN);}}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          labelPlacement="start"
                          label={props.t('approved')}
                        />}
                        {props.login.sft_status !== "3" && <EmailEditor
                            minHeight={700}
                            ref={emailEditorRefEN}
                            onLoad={onLoad}
                            onReady={onReady}
                            appearance={
                                {
                                    theme: props.theme,
                                    panels: {
                                      tools: {
                                        dock: 'right'
                                      }
                                    }
                                }
                            }
                            options={{
                                displayMode: "web", 
                                fonts: {
                                    customFonts: [
                                        {
                                            label: "Urbane Rounded",
                                            value: "'Urbane Rounded',cursive",
                                            url: "https://my.softtouch.eu/files/fonts/stylesheet.css",
                                            weights: [{label: 'Regular', value: 600}]
                                        }
                                    ]
                                }
                               
                            }}
                        />}
                        {props.login.sft_status === "3" && <div style={{height: "fit-content"}} dangerouslySetInnerHTML={{__html: htmlEN}}></div>}
                    </div>}
              </TabPanel>
                
                
            </div>}
        </div>
    );

}
export default ManualEditScreen;
import React, { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { TextField, Autocomplete, Button, Typography, Grid, Paper, Switch, FormControlLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import { getISOWeek, getYear, getMonth } from 'date-fns';
import SftGlobalData from '../library/SftGlobalData';
import axios from 'axios';
import Colors from '../library/Colors';

// List of people for selection sorted alphabetically
const peopleList = ["Els", "Maud", "Ruben", "Mathias", "Bert", "Thibeau", "Sander", "Louis", "Gianni", "Jeroen", "Tom", "Kristof"].sort();

// Empty schedule structure
const emptySchedule = {
  cleaning: [],
  responsible: "",
  first_break: [],
  last_break: [],
  announcement: "",
  home_dev: false,
  home_support: false,
};

const ScheduleForm = (props) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [week, setWeek] = useState(props?.default ? 0 : getISOWeek(new Date()));
  const [month, setMonth] = useState(props?.default ? 0 : getMonth(new Date()));
  const [year, setYear] = useState(props?.default ? "0000" : getYear(new Date()));
  const [schedule, setSchedule] = useState({
    monday: { ...emptySchedule },
    tuesday: { ...emptySchedule },
    wednesday: { ...emptySchedule },
    thursday: { ...emptySchedule },
    friday: { ...emptySchedule },
  });

  // Fetch schedule for the selection
  useEffect(() => {
    let newWeek = 0;
    let newMonth = 0;
    let newYear = "0000";
    if(!props?.default){
        newWeek = getISOWeek(selectedDate.toDate());
        newMonth = getMonth(selectedDate.toDate());
        newYear = getYear(selectedDate.toDate());
        setWeek(newWeek);
        setMonth(newMonth);
        setYear(newYear);
    }
    
    fetchSchedule(newYear, newWeek, newMonth); // Fetch data
  }, [selectedDate]);

  const fetchSchedule = async (selectedYear, selectedWeek, selectedMonth) => {
    try {
        let url = SftGlobalData.baseURL_API + 'schedule&action=fetch';
        let postData = props.mode === "month" ? {year: selectedYear, month: selectedMonth} : {year: selectedYear, week: selectedWeek};

        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log("URL", url);
                    console.log("POST", postData);
                    console.log("RESP", response?.data);
                }
                if(typeof response?.data?.schedule !== "undefined")
                    setSchedule(response?.data?.schedule);
                else {
                    setSchedule({
                        monday: { ...emptySchedule },
                        tuesday: { ...emptySchedule },
                        wednesday: { ...emptySchedule },
                        thursday: { ...emptySchedule },
                        friday: { ...emptySchedule },
                      });
                }
            });
        
    } catch (error) {
      console.error('Error fetching schedule:', error);
    }
  };

  // Handle input changes for each day and field
  const handleInputChange = (day, field, value) => {
    console.log("day", day);
    console.log("field", field);
    console.log("value", value);
    setSchedule(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        [field]: value
      }
    }));
  };

  // Handle form submission (saving schedule)
  const handleSubmit = async () => {
    try {
        let url = SftGlobalData.baseURL_API + 'schedule&action=update';
        let postData = props?.mode === "month" ? {year: year, month: month, schedule: schedule } : {year: year, week: week, schedule: schedule};
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log("URL", url);
                    console.log("POST", postData);
                    console.log("RESP", response?.data);
                }
                if(response?.data?.success)
                    props.notify(props.t('savedChanges'), 7500, "top-right", 1);
                else
                    props.notify(props.t('errorOccured') + " " + response?.data?.message, 7500, "top-right", 1); 
            });
    } catch (error) {
        props.notify(props.t('errorOccured'), 7500, "top-right", 1); 
        console.error('Error saving schedule:', error);
    }
  };

  const handleDefault = async () => {
    try {
        let url = SftGlobalData.baseURL_API + 'schedule&action=default';
        let postData = props?.mode === "month" ? {year: year, month: month} : {year: year, week: week};
        axios.post(url, postData, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
            .then(response => {
                if(SftGlobalData.debug){
                    console.log("URL", url);
                    console.log("POST", postData);
                    console.log("RESP", response?.data);
                }
                if(response?.data?.success){
                    fetchSchedule(year, week, month);
                    props.notify(props.t('savedChanges'), 7500, "top-right", 1);
                } else
                    props.notify(props.t('errorOccured') + " " + response?.data?.message, 7500, "top-right", 1); 
            });
    } catch (error) {
        props.notify(props.t('errorOccured'), 7500, "top-right", 1); 
        console.error('Error saving schedule:', error);
    }
  };

  // Render form inputs for each day
  const renderDayForm = (day) => (
    <Paper 
        elevation={2} 
        style={{ padding: '10px', marginBottom: '16px', minWidth: 320, maxWidth: 320, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}
        sx={props.theme === "dark" ? {} : schedule[day].home_support ? {backgroundColor: "#FFC000"} : schedule[day].home_dev ? {backgroundColor: "#F4B083"} : {}}
    >
      <Typography variant="h6" >{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
      <br/>
      {/* Cleaning: Multi-select Autocomplete */}
      <Autocomplete
        disabled={props.disabled}
        multiple
        options={peopleList}
        value={schedule[day].cleaning}
        onChange={(e, newValue) => handleInputChange(day, "cleaning", newValue)}
        renderInput={(params) => <TextField {...params} className={props.theme} size={'small'}
        sx={(props.theme==='light') ? {
            '& .MuiOutlinedInput-input': {
                color: Colors.light_text
              },
            '& .MuiInputLabel-root': {
                color: Colors.light_text
             }, borderRadius: 1, marginTop: 0} 
            :
            {
            '& .MuiOutlinedInput-input': {
                color: Colors.dark_text
                },
                '& .MuiInputLabel-root': {
                    color: Colors.dark_text_accent
                }, borderRadius: 1, marginTop: 0}} label="Cleaning"/>}
      /><br/>

      {/* Responsible: Single-select Autocomplete */}
      <Autocomplete
        disabled={props.disabled}
        options={peopleList}
        value={schedule[day].responsible}
        onChange={(e, newValue) => handleInputChange(day, "responsible", newValue)}
        renderInput={(params) => <TextField {...params} className={props.theme} size={'small'}
        sx={(props.theme==='light') ? {
            '& .MuiOutlinedInput-input': {
                color: Colors.light_text
              },
            '& .MuiInputLabel-root': {
                color: Colors.light_text
             }, borderRadius: 1, marginTop: 0} 
            :
            {
            '& .MuiOutlinedInput-input': {
                color: Colors.dark_text
                },
                '& .MuiInputLabel-root': {
                    color: Colors.dark_text_accent
                }, borderRadius: 1, marginTop: 0}} label="Responsible" />}
      />
      <br />

      {/* First Break: Multi-select Autocomplete */}
      <Autocomplete
        disabled={props.disabled}
        multiple
        options={peopleList}
        value={schedule[day].first_break}
        onChange={(e, newValue) => handleInputChange(day, "first_break", newValue)}
        renderInput={(params) => <TextField {...params} className={props.theme} size={'small'}
        sx={(props.theme==='light') ? {
            '& .MuiOutlinedInput-input': {
                color: Colors.light_text
              },
            '& .MuiInputLabel-root': {
                color: Colors.light_text
             }, borderRadius: 1, marginTop: 0} 
            :
            {
            '& .MuiOutlinedInput-input': {
                color: Colors.dark_text
                },
                '& .MuiInputLabel-root': {
                    color: Colors.dark_text_accent
                }, borderRadius: 1, marginTop: 0}} label="First Break" />}
      />
      <br />

      {/* Last Break: Multi-select Autocomplete */}
      <Autocomplete
        disabled={props.disabled}
        multiple
        options={peopleList}
        value={schedule[day].last_break}
        onChange={(e, newValue) => handleInputChange(day, "last_break", newValue)}
        renderInput={(params) => <TextField {...params} className={props.theme} size={'small'}
        sx={(props.theme==='light') ? {
            '& .MuiOutlinedInput-input': {
                color: Colors.light_text
              },
            '& .MuiInputLabel-root': {
                color: Colors.light_text
             }, borderRadius: 1, marginTop: 0} 
            :
            {
            '& .MuiOutlinedInput-input': {
                color: Colors.dark_text
                },
                '& .MuiInputLabel-root': {
                    color: Colors.dark_text_accent
                }, borderRadius: 1, marginTop: 0}} label="Last Break" />}
      />
      <br />

      {/* Announcement */}
      <TextField
        disabled={props.disabled}
        label="Announcement"
        value={schedule[day].announcement}
        onChange={(e) => handleInputChange(day, "announcement", e.target.value)}
        className={props.theme} size={'small'}
        sx={(props.theme==='light') ? {
            '& .MuiOutlinedInput-input': {
                color: Colors.light_text
              },
            '& .MuiInputLabel-root': {
                color: Colors.light_text
             }, borderRadius: 1, marginTop: 0} 
            :
            {
            '& .MuiOutlinedInput-input': {
                color: Colors.dark_text
                },
                '& .MuiInputLabel-root': {
                    color: Colors.dark_text_accent
                }, borderRadius: 1, marginTop: 0}} 
        multiline
        fullWidth
      /> <br />

      <FormControlLabel
          sx={{textAlign: 'left', marginLeft: 0, marginBottom: 1, width: '100%', justifyContent: "space-between"}}
          control={
            <Switch
              disabled={props.disabled}
              checked={ schedule[day].home_dev }
              onChange={(e) => handleInputChange(day, "home_dev", !schedule[day].home_dev)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          labelPlacement="start"
          label={"Home development"}
      />

      <FormControlLabel
          sx={{textAlign: 'left', marginLeft: 0, marginBottom: 1, width: '100%', justifyContent: "space-between"}}
          control={
            <Switch
              disabled={props.disabled}
              checked={ schedule[day].home_support }
              onChange={(e) => handleInputChange(day, "home_support", !schedule[day].home_support)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          labelPlacement="start"
          label={"Home support"}
      />
    </Paper>
  );


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ width: "100%"}}>
        <div className='rowContainerBetween'style={{alignItems: "center", marginBottom: 10}}>
            {!props?.default && <div className='rowContainerStart' style={{alignItems: "center"}}>
                {/* DatePicker for selecting the week */}
                <DatePicker
                  label={props.mode !== "month" ? "Select week" : "Select month"}
                  value={selectedDate}
                  format="DD/MM/YYYY"
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Typography variant="subtitle1">&nbsp;&nbsp;&nbsp;&nbsp;{props.mode !== "month" ? <span><b>Week:</b> {week}</span> : <span><b>Month:</b> {month+1}</span>}&nbsp;&nbsp;&nbsp;&nbsp; <b>Year:</b> {year}</Typography>
            </div>}
            {props?.default && <div></div>}
            {/* Save button to submit the schedule */}
            {!props.disabled && <div>
                {!props.default && <Button variant="contained" color="primary" onClick={handleDefault} style={{marginRight: 10}}>Apply default</Button>}
                <Button variant="contained" color="primary" onClick={handleSubmit}>Save Schedule</Button>
            </div>}
        </div>

        {/* Grid Layout for responsive form */}
        <Grid container spacing={3}>
          {/* Loop through the days (Monday to Friday) and render the form for each */}
          {["monday", "tuesday", "wednesday", "thursday", "friday"].map(day => (
            <Grid item xs={"auto"} sm={"auto"} md={"auto"} lg={"auto"} key={day}>
              {renderDayForm(day)}
            </Grid>
          ))}
        </Grid>
      </div>
    </LocalizationProvider>
  );
};

export default ScheduleForm;

import React, { useState, useEffect } from 'react';
import { TailSpin } from  'react-loader-spinner';
import { useParams, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from 'axios';

// Constants
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';

// Components
import CardSliderWithImage from '../components/CardSliderWithImage';
import ApplicationContainer from '../components/ApplicationContainer';

// Assets
import testLogo from '../assets/Test.png';
import mobileLogo from '../assets/module-icons/ic_mobile.png'
import usersLogo from '../assets/module-icons/ic_users.png'
import shippingLogo from '../assets/module-icons/ic_shipping.png'

import ic_mysft from '../assets/application-icons/ic_mysft.png';
import ic_revenue from '../assets/application-icons/ic_revenue.png';
import RevenueSettingsForm from '../components/RevenueSettingsForm';
import FloatingBackButton from '../components/FloatingBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';



const UserDetailAppScreen = (props) => {
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const params = useParams();

    let isUpdated = false;

    // MY SFT
    let mySftActive = false;
    let mobileActive = false;
    let shippingLogActive = false;
    let userManagementActive = false;

    const generateJsonMySft = () => {
        return JSON.stringify(
            {
                modules:
                [
                    {
                        group: "web",
                        visible: 1,
                        groupData: [
                            {
                                module: "shipping-log",
                                name: "Shipping log",
                                active: shippingLogActive ? 1 : 0
                            }
                        ]
                    },
                    {
                        group: "admin",
                        visible: 1,
                        groupData: [
                            {
                                module: "users",
                                name: "User management",
                                active: userManagementActive ? 1 : 0
                            }
                        ]
                    },
                    {
                        group: "other",
                        visible: 1,
                        groupData: [
                            {
                                module: "mobile",
                                name: "MyFasMan Mobile",
                                active: mobileActive ? 1 : 0
                            }
                        ]
                    }
                ]
            }
        );
    }

    // REVENUE
    let revenueAppActive = false;
    let revenueAppJson = "";

    const setRevenueAppJson = (value) => {
        revenueAppJson = JSON.stringify(value);
        isUpdated = true;
    }
    
    const [apps, setApps] = useState([]);

    // wijzigen - /nl/user-detail/{userId}/{appId}
    // nieuw - /nl/user-detail/{userId}/apps
    // defaults - /nl/app-defaults

    useEffect(() => {
        if(props.api !== ''){
            if(!props.defaults){
                let url = SftGlobalData.baseURL_API + 'account&action=fetchOne';
                axios.post(url, 
                    {
                        id: params.userId,
                        key: props.api
                    }, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    }).then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('data', {id: params.userId, key: props.api});
                            console.log('response', response);
                        }
                        if(params.appId && params.appId !== "apps"){
                            if(SftGlobalData.debug){
                                console.log("1 app", apps[params.appId-1]);
                            }
                            setApps([response.data.applications[params.appId-1]]);
                        } else {
                            setApps(response.data.applications);
                        }
                        setLoading(false);
                    }
                );
            } else {
                let url = SftGlobalData.baseURL_API + 'appDefaults&action=fetch';
                axios.post(url, 
                    {
                        id: params.userId,
                        key: props.api
                    }, {
                        headers : {
                            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                        }
                    }).then(response => {
                        if(SftGlobalData.debug){
                            console.log('url', url);
                            console.log('data', {id: params.userId, key: props.api});
                            console.log('response', response);
                        }
                        setApps(response.data.applications);
                        setLoading(false);
                    }
                );
            }
        }
     },[props.api]);


    // MY SFT
    const setMySftActive = (value) => {
        mySftActive = value;
        isUpdated = true;
    }
    const setUserManagementActive = (value) => {
        userManagementActive = value;
        isUpdated = true;
    }
    const setShippingLogActive = (value) => {
        shippingLogActive = value;
        isUpdated = true;
    }
    const setMobileActive = (value) => {
        mobileActive = value;
        isUpdated = true;
    }
    // REVENUE APP
    const setRevenueAppActive = (value) => {
        revenueAppActive = value;
        isUpdated = true;
    }

    // Form submit
    const handleSubmit = () => {
        if(!props.defaults){
            let url =  SftGlobalData.baseURL_API + 'account&action=updateApp';
            
            if(params.appId === "apps"){
                url =  SftGlobalData.baseURL_API + 'account&action=updateApps';
            } 
            let postData = {
                key: props.api,
                user_id: params.userId,
                app_id: params.appId,
                
                my_sft_active: mySftActive,
                revenue_app_active: revenueAppActive,

                my_sft_json: generateJsonMySft(),
                revenue_app_json: revenueAppJson
            }
            
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log("url", url);
                        console.log("data", postData);
                        console.log('response', response);
                    }
                });
            isUpdated = false;
            alert(props.t('savedChanges'));
        } else {
            let url =  SftGlobalData.baseURL_API + 'appDefaults&action=update';
            let postData = {
                key: props.api,
                my_sft_active: mySftActive,
                revenue_app_active: revenueAppActive,

                my_sft_json: generateJsonMySft(),
                revenue_app_json: revenueAppJson
            }
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log("url", url);
                        console.log("data", postData);
                        console.log('response', response);
                    }
                });
                isUpdated = false;
            alert(props.t('savedChanges'));
        }
    };


    const getApps = () => {
        if(apps.length > 0){
            if(SftGlobalData.debug){
                console.log("applicationsmapper", apps);
            }
            
            
            return apps.map(app => {
                //console.log('app',app);
                let containerImage = testLogo;
                let content = (<div></div>);
                let containerFunc = (val) => {};
                switch(app.id){
                    case "1":
                        //console.log('modules', app.settings.modules);
                        containerFunc = (val) => {setMySftActive(val);};
                        mySftActive = app.user_active === "1";
                        containerImage = ic_mysft;
                        if(app.settings.modules){
                            content = app.settings.modules.map(module => {
                                const mapping = module.groupData.map(groupData => {
                                    if(SftGlobalData.debug){
                                        console.log('groupData', groupData);
                                    }
                                    let cardImage = testLogo;
                                    let func = () => {};
                                    switch(groupData.module){
                                        case 'mobile':
                                            mobileActive = groupData.active === 1;
                                            func = (val) => {setMobileActive(val);};
                                            cardImage = mobileLogo;
                                            break;
                                        case 'users':
                                            userManagementActive = groupData.active === 1;
                                            func = (val) => {setUserManagementActive(val);};
                                            cardImage = usersLogo;
                                            break;
                                        case 'shipping-log':
                                            shippingLogActive = groupData.active === 1;
                                            func = (val) => {setShippingLogActive(val);};
                                            cardImage = shippingLogo;
                                            break;
                                        default:
                                            cardImage = testLogo;
                                            break;
                                    }
                                    return(<CardSliderWithImage key={groupData.module} uri={"/"+props.lang+"/user-detail/"+params.userId+"/"+params.appId} noClick={true} title={groupData.name} image={cardImage} theme={props.theme} data={groupData} type={"mysft"} lang={props.lang} userId={params.userId} appId={params.appId} changeActive={func} active={groupData.active}/>)
                                });
                                return (mapping);
                            });
                        }
                        break;
                    case "2":
                        containerFunc = (val) => {setRevenueAppActive(val);};
                        revenueAppActive = app.user_active === "1";
                        containerImage = ic_revenue;
                        content = (
                            <RevenueSettingsForm api={props.api} theme={props.theme} setJson={(val)=>setRevenueAppJson(val)} settings={app.settings}/>
                        );
                        break;
                    default:
                        containerImage = testLogo;
                        content = (<div></div>);
                        break;
                }

                //console.log(content);

                return (
                    <div key={app.id}>
                        <ApplicationContainer icon={containerImage} title={app.description} appId={parseInt(app.id)} active={app.user_active==="1"} theme={props.theme} t={props.t} func={containerFunc}>
                            {content}
                            <hr/>
                        </ApplicationContainer>
                    </div>
                )
            });
        } else {
            return (<div></div>);
        }
    }

    const confirmAction = () => {
        if(isUpdated === true){
            let confirmAction = window.confirm(props.t('save_changes_question'));
            if (confirmAction) {
                navigate(props.defaults ? "/"+props.lang+"/users" : "/"+props.lang+"/user-detail/"+params.userId);
            } 
        } else {
            navigate(props.defaults ? "/"+props.lang+"/users" : "/"+props.lang+"/user-detail/"+params.userId);
        }
      }

    return (
            <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme} >
                <FloatingBackButton disabled={false} onClick={()=>{confirmAction()}} />
                <FloatingSaveButton disabled={loading} onClick={()=>{handleSubmit();}} />
                <div className={"container_list__title " + props.theme}><h2>{props.t('users')} {props.t('settings')}</h2></div>
                <form>
                    {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>} 

                    {!loading && getApps()}
                </form>
            </div>
    );
}

export default UserDetailAppScreen;

import React, { useState } from 'react';
import {
    useNavigate
  } from "react-router-dom";

const CardSliderWithImage = (props) =>  {
    const [checkedState, setCheckedState] = useState(parseInt(props.active) === 1 ? true : false);
    let navigate = useNavigate();

    const changeState = (state) => {
        switch(props.type){
            case "application":
                let apps = [];
                props.applications.forEach(element => {
                    if(props.data.id === element.id){
                        apps.push({
                            id: props.data.id,
                            description: props.data.description,
                            is_active: props.data.is_active,
                            user_active: state ? "1" : "0",
                            is_debug: props.data.is_debug,
                            debug: props.data.debug
                        });
                    } else {
                        apps.push(element);
                    }
                });
                props.changeState(apps);
                break;
            case "mysft":
                props.changeActive(state);
                break;
            default:
                break;
        }
        
    }

    return (
        <div className={"card_container " + props.theme + "_accent card_container_" + props.theme}>
            <div onClick={()=>{if(!props.noClick){navigate(props.uri)}}}>
                <img src={props.image} alt={props.title} width="64" height="64"/>
                <p>{props.title}</p>
            </div>
            <label className="switch" style={{visibility: (props.title === "General" ? "hidden" : "visible")}}>
                <input type="checkbox" checked={checkedState} onChange={() => {setCheckedState(!checkedState); changeState(!checkedState)}}/>
                <span className={"slider slider_" + props.theme + " round"}></span>
            </label>
        </div>
    );
}

export default CardSliderWithImage;

import React, { useState, useEffect, useRef, useCallback }from 'react';
import SftGlobalData from '../../library/SftGlobalData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faLock, faFile, faHdd, faUpload, faCreditCard, faCheck, faIndent, faSync, faSave, faCalendarPlus, faLevelUpAlt, faKey, faMobile, faMobileAlt, faCheckCircle, faPlay, faStop, faQuestion, faBurger} from '@fortawesome/free-solid-svg-icons'
import Colors from '../../library/Colors';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ModalUnstyled from '@mui/base/ModalUnstyled';

import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Input } from '@mui/material';
import {
  DataGridPro,
  GridToolbarContainer,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarExportContainer,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    text-align: center;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    `;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    `;

const style = {
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: 6,
    p: 2,
    px: 4,
    pb: 3,
    };

function DetailPanelContent({ row }) {
    const apiRef = useGridApiContext();
    const [remark, setRemark] = useState(row.remark);

    const {
        control,
        handleSubmit,
        formState: { isValid },
      } = useForm({
        defaultValues: row,
        mode: 'onChange',
      });
  
    const onSubmit = (data) => {
        apiRef.current.updateRows([data]);
    };
  
    return (
      <Stack
        sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
        direction="column"
        className={row.theme}

      >
        <Paper style={{ flex: 1, margin: row.screenWidth > 900 ? 'auto' : '0', display: 'flex', flexDirection: 'row', width: row.screenWidth > 900 ? '95%' : row.screenWidth, padding: 1, backgroundColor: row.theme==="light"?Colors.light_basic:Colors.dark_basic}}>

            <div className={'lic_info_container ' + row.theme}>
                {row.screenWidth > 900 && (row.type !== "9" && row.type !== "10") && <div>
                    <div style={{padding: 2, marginBottom: 10}}><FontAwesomeIcon icon={faCreditCard} color={Colors.sft_blue} />&nbsp; <b>{row.t("payclient")}: </b>{row.payclnt} ({row.payclnt_dll === "" ? '---' : row.payclnt_dll})</div>
                    <table style={{marginBottom: 10}}>
                        <tbody>
                            <tr>
                                <td className={"padding_right__15px"}><FontAwesomeIcon icon={faCheck} color={Colors.sft_blue} /> <b>{row.t("disk_id_check")}: </b></td>
                                <td className={"padding_right__15px"}>{row.diskid_check}</td>
                                <td className={"padding_right__15px"}><FontAwesomeIcon icon={faIndent} color={Colors.sft_blue} /> <b>{row.t("disk_id")}: </b></td>
                                <td className={"padding_right__5px"}>{row.diskid}</td>
                                <td className={"padding_right__5px bold "} style={{textAlign: "right"}}>{row.windows_version === "" ? '-' : row.windows_version}</td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px"}><FontAwesomeIcon icon={faCheck} color={Colors.sft_blue} /> <b>{row.t("guid_changes")}: </b></td>
                                <td className={"padding_right__15px"}>{row.guid_changes}</td>
                                <td className={"padding_right__15px"}><FontAwesomeIcon icon={faIndent} color={Colors.sft_blue} /> <b>{row.t("guid")}: </b></td>
                                <td className={"padding_right__5px"}>{row.guid}</td>
                                <td>{row.suppCheck && <Button className={"sft_blue_text"} onClick={()=>{row.resetGuidChanges(row.id);}}><FontAwesomeIcon icon={faSync} color={Colors.sft_blue} />&nbsp; {row.t("reset")} </Button>}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div style={{padding: 5}}>
                        <Typography variant="h6" >{row.t("remark")}: {row.suppCheck && <Button onClick={()=>row.updateRemark(row.id, remark)} className={"button_inverted margin_bottom_5px height_30px"} style={{float: 'right', width: 120}}><FontAwesomeIcon icon={faSave} color={Colors.sft_blue} />&nbsp; {row.t("save")} </Button>}</Typography>
                        <textarea className={(row.theme==='light') ? 'light_accent_darker textarea margin_bottom_none' : 'dark_accent_darker textarea margin_bottom_none'} id="remark" onChange={(event)=>{setRemark(event.target.value)}} name="remark" value={remark} style={{resize: 'none', minHeight: 75}}/>
                    </div>
                </div>}

                {(row.type === "9" || row.type === "10") && row.screenWidth > 900 && <div>
                    <table style={{marginBottom: 10}}>
                        <tbody>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={faKey} color={Colors.sft_blue} /> <b>{row.t("licence_key")}: </b></td>
                                <td className={"padding_right__15px padding_bottom__5px"}>{row.guid}</td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={faMobileAlt} color={Colors.sft_blue} />&nbsp;&nbsp;<b>{row.t("device")}: </b></td>
                                <td className={"padding_right__5px padding_bottom__5px"}>{row.diskid}</td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={faMobile} color={Colors.sft_blue} />&nbsp;&nbsp;<b>{row.t("unique_device_id")}: </b></td>
                                <td className={"padding_right__5px padding_bottom__5px"}>{row.remark === "" ? '-' : row.remark}</td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={row.OS_name === "iOS" ? faApple : row.OS_name === "Android" ? faAndroid : faQuestion} color={Colors.sft_blue} />&nbsp;&nbsp;<b>OS:</b></td>
                                <td className={"padding_right__5px padding_bottom__5px"}>{row.windows_version === "" ? '-' : row.windows_version}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                {(row.type === "9" || row.type === "10") && row.screenWidth <= 900 && <div>
                    <table style={{marginBottom: 10}}>
                        <tbody>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={faKey} color={Colors.sft_blue} /> <b>{row.t("licence_key")}: </b></td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}>{row.guid}</td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={faMobileAlt} color={Colors.sft_blue} />&nbsp;&nbsp;<b>{row.t("device")}: </b></td>
                            </tr>
                            <tr>
                                <td className={"padding_right__5px padding_bottom__5px"}>{row.diskid}</td>
                            </tr>
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={faMobile} color={Colors.sft_blue} />&nbsp;&nbsp;<b>{row.t("unique_device_id")}: </b></td>
                            </tr>
                            <tr>
                                <td className={"padding_right__5px padding_bottom__5px"}>{row.remark === "" ? '-' : row.remark}</td>
                            </tr>                            
                            <tr>
                                <td className={"padding_right__15px padding_bottom__5px"}><FontAwesomeIcon icon={row.OS_name === "iOS" ? faApple : row.OS_name === "Android" ? faAndroid : faQuestion} color={Colors.sft_blue} />&nbsp;&nbsp;<b>OS: </b></td>
                            </tr>
                            <tr>
                                <td className={"padding_right__5px padding_bottom__5px"}>{row.windows_version === "" ? '-' : row.windows_version}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </div>
            <div className={'lic_button_container ' + row.theme} style={{float: 'right'}}>
              {row.suppCheck && <Button className={"modal_button_container_no_margin_5px_button_inverted"} style={{border: "1px solid " + Colors.sft_blue}} onClick={()=>{row.editLicenceSetup(row.description, row.is_trial, row.diskid_check, row.invoice); row.targetModalInner("Licence_confirm", "edit", row.id);}}>{row.t("edit")}</Button>}
              {row.adminCheck && <Button className={"modal_button_container_no_margin_5px_button"} style={{backgroundColor: Colors.sft_blue}} onClick={()=>row.targetModalInner("Licence_confirm", (row.is_trial==="1" ? "extendTrial" : "extend"), row.id)} disabled={row.tags.active==="0"}>{row.t("extend")}</Button>}
              {row.suppCheck && <Button className={"modal_button_container_no_margin_5px_button_inverted"} style={{border: "1px solid " + Colors.sft_blue}}  onClick={()=>{row.tags.update = "1"; row.autoUpdate(row.id); handleSubmit(onSubmit);}} disabled={row.tags.active==="0"}>{row.t("auto_update")}</Button>}
              {row.suppCheck && <Button className={"modal_button_container_no_margin_5px_button"} style={{backgroundColor: Colors.def_orange}} onClick={()=>row.targetModalInner("Licence_confirm", "release", row.id)} disabled={row.tags.active==="0"}>{row.t("release")}</Button>}
              {row.adminCheck && <Button className={"modal_button_container_no_margin_5px_button"} style={{backgroundColor: row.tags.active === "1" ? Colors.def_red : Colors.def_green}} onClick={()=>row.targetModalInner("Licence_confirm", "status", row.id)}>{row.tags.active === "1" ? row.t("disable") : row.t("enable")}</Button>}
              {row.adminCheck && <Button className={"modal_button_container_no_margin_5px_button"} style={{backgroundColor: Colors.def_red}} onClick={()=>row.targetModalInner("Licence_confirm", "delete", row.id)}>{row.t("delete")}</Button>}
              {row.suppCheck && <Button className={"modal_button_container_no_margin_5px_button_inverted"} style={{border: "1px solid " + Colors.sft_blue}}  onClick={()=>row.targetModal("Licence_history", "", row.id)}>{row.t("history")}</Button>}
            </div>
        </Paper>
      </Stack>
    );
  }

  function TestCell (props) {
    const apiRef = useGridApiContext();
      return (<div onClick={()=>{apiRef.current.toggleDetailPanel(props.id);}} style={{width: '100%', height: '100%', alignContent: 'center', alignItems: 'center', display: 'flex'}}><span>{props.value}</span></div>)
  }

  function ContentCell (props) {
    const apiRef = useGridApiContext();
      return (<div onClick={()=>{apiRef.current.toggleDetailPanel(props.id);}} style={{width: '100%', height: '100%', alignContent: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>{props.children}</div>)
  }
  
  DetailPanelContent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  function CustomDetailPanelToggle(props) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();
  
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );
  
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);
  
    return (
      <IconButton
        size="small"
        tabIndex={-1}
        disabled={!hasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
      >
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
  }
  
  CustomDetailPanelToggle.propTypes = {
    /**
     * The grid row id.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.any,
  };

const LicenceTableForm = (props) =>  {
    const [loading, setLoading] = useState(true);
    const [licences, setLicences] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [modalAction, setModalAction] = useState("");
    const [modalTarget, setModalTarget] = useState(0);
    const [modalValue, setModalValue] = useState("");
    const [modalValueDescr, setModalValueDescr] = useState("");

    let inputExt = useRef(null);

    useEffect (()=>{
        loadLicences();
    }, [props.api]);

    useEffect(()=>{
        props.fixLicenceData(licences);
    }, [licences])

    useEffect (()=>{
        if(licences.length > 0){
            setLicences(props.licenceData);
        }
    }, [props.licenceData]);

    const loadLicences = async () =>{
        try{
            let url = SftGlobalData.baseURL_API+'client&action=fetchLicences';
            let postData = {key: props.api, lic_type: props.type}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    setLicences(response.data);
                    setLoading(false);
                });
        } catch(e){
            console.error(e);
        }
    }

    const groupAutoUpdate = async () => {
        setLoading(true);
        
        try{
            let url = SftGlobalData.baseURL_API + 'client&action=autoUpdate';
            let postData = {key: props.api, id: props.type, mode: 'group', user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    loadLicences();
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                });
        } catch(e){
            console.error(e);
        }
    }

    const groupTrialOff = async () => {
        setLoading(true);
        
        try{
            let url = SftGlobalData.baseURL_API + 'client&action=trialOff';
            let postData = {key: props.api, id: props.type, mode: 'group', user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    loadLicences();
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                });
        } catch(e){
            console.error(e);
        }
    }

    const resetGuidChanges = async (id) => {
        setLoading(true);
        
        try{
            let url = SftGlobalData.baseURL_API + 'client&action=resetGuid';
            let postData = {key: props.api, id: id, mode: 'single', user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    loadLicences();
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                });
        } catch(e){
            console.error(e);
        }
    }

    const updateRemark = async (id, remark) => {
        try{
            let url = SftGlobalData.baseURL_API + 'client&action=remark';
            let postData = {key: props.api, id: id, remark: remark, mode: 'single', user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                });
        } catch(e){
            console.error(e);
        }
    }

    const autoUpdate = async (id) => {
        try{
            let url = SftGlobalData.baseURL_API + 'client&action=autoUpdate';
            let postData = {key: props.api, id: id, mode: 'single', user: props.username, sftUserId: props.userId}
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
                });
        } catch(e){
            console.error(e);
        }
    }

    const GridToolbarExport = ({ csvOptions, printOptions, ...other }) => (
        <GridToolbarExportContainer {...other}>
          <GridCsvExportMenuItem options={csvOptions} />
          <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    );


    const dataContainer = useGridApiRef(); 
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
                {props.screenWidth > 900 && props.adminCheck && <Button disabled={licences.length === 0} onClick={()=>targetModalContent("Licence_confirm", "groupExtend", props.type)} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faCalendarPlus} color={Colors.sft_blue} />&nbsp; {props.t("group_extend")}</Button>}
                {props.screenWidth > 900 && <Button disabled={licences.length === 0} onClick={()=>groupAutoUpdate()} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faLevelUpAlt} color={Colors.sft_blue} />&nbsp; {props.t("auto_update")}</Button>}
                {props.screenWidth > 900 && props.adminCheck && <Button disabled={licences.length === 0} onClick={()=>groupTrialOff()} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faCheckCircle} color={Colors.sft_blue} />&nbsp; {props.t("trial_off")}</Button>}
                {props.screenWidth > 900 && props.adminCheck && <Button disabled={licences.length === 0} onClick={()=>{targetModalContent("Licence_confirm", "groupStatusOn", licences[0].type); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faPlay} color={Colors.sft_blue} />&nbsp; {props.t("on_status")}</Button>}
                {props.screenWidth > 900 && props.adminCheck && <Button disabled={licences.length === 0} onClick={()=>{targetModalContent("Licence_confirm", "groupStatusOff", licences[0].type); setModalOpen(true);}} className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faStop} color={Colors.sft_blue} />&nbsp; {props.t("off_status")}</Button>}
                {props.screenWidth > 900 && props.devCheck && <GridToolbarExport 
                                        csvOptions={{ fields: ['id', 'description', 'prog_version', 'pos_id', 'valid_until', 'last_used'], utf8WithBom: true }} 
                                        printOptions={{hideFooter: true,hideToolbar: true, allColumns: false, utf8WithBom:true, fields: ['description', 'prog_version', 'pos_id', 'valid_until', 'last_used']}} 
                                        className={"sft_blue_text"} 
                                        style={{justifyContent: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', fontSize: 14, height: 36}} />}
                <Button disabled={licences.length === 0} onClick={()=>{dataContainer.current.setExpandedDetailPanels([])}}className={"sft_blue_text margin_right__10px"}><FontAwesomeIcon icon={faBurger} color={Colors.sft_blue} />&nbsp; Collapse all</Button>
          </GridToolbarContainer>
        );
      }

    const targetModalContent = (content, action, target) => {
        setModalContent(content);
        setModalAction(action);
        setModalTarget(target);
        if(action==="extend" || action === "groupExtend"){
            if(modalValue === ""){
                setModalValue(props.licenceDate);
            }
        }
        if(action==="extendTrial"){
            var future = new Date();
            future.setDate(future.getDate() + 30);
            setModalValue(future.toISOString().substring(0, 10));
        }
        setModalOpen(true);
    }

    const licenceAPICall = async (url, mode) => {
        try{
            let postData = { key: props.api, mode: mode, id: modalTarget, value: modalValue, user: props.username, sftUserId: props.userId};
            if(modalAction === "edit"){
                postData = { key: props.api, invoice: invoice, is_trial: trial, diskid_check: diskId, description: modalValueDescr, id: modalTarget, user: props.username, sftUserId: props.userId };
            }
            
            axios.post(url, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('postData', postData);
                        console.log('resp', response);
                    }
                    loadLicences();
                    props.notify(props.t('savedChanges'));
                });
        } catch(e){
            console.error(e);
        }
    } 

    const [trial, setTrial] = useState(false);
    const [diskId, setDiskId] = useState(true);
    const [invoice, setInvoice] = useState(true);

    const licenceEditSetup = (descr, trial, check, invoice) => {
        setModalValueDescr(descr);
        setTrial(trial === "1");
        setDiskId(check === "1");
        setInvoice(invoice === "1");
    }

    const toggleTrial = () => {
        setTrial(!trial);
    }

    const toggleDiskId = () => {
        setDiskId(!diskId);
    }

    const toggleInvoice = () => {
        setInvoice(!invoice);
    }

    const getModalContent = () => {
        let url = "";
        let mode = "single";
        
        switch(modalContent) {
            case "Licence_confirm":
                let extraHTML = <div></div>
                let title = "";

                switch(modalAction){
                    case "groupStatus":
                        title = props.t("toggle_status_group") + props.type + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=status';
                        mode = "group";
                        break;
                    case "groupStatusOn":
                        title = props.t("on_status_group") + props.type + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=status';
                        mode = "groupOn";
                        break;
                    case "groupStatusOff":
                        title = props.t("off_status_group") + props.type + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=status';
                        mode = "groupOff";
                        break;
                    case "groupExtend":
                        title = props.t("extend_group") + props.type + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=extend';
                        mode = "group";
                        extraHTML = <div>
                                        <p><b>{props.t("days_valid")}:</b> {props.daysValid}</p>
                                        <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Value' value={modalValue} onChange={(e)=>{setModalValue(e.target.value);}}/>
                                    </div>;
                        break;
                    case "extendTrial":
                    case "extend":
                        return <div></div>
                        break;
                    case "delete":
                        title = props.t("delete_licence")+ modalTarget + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=delete';
                        mode = "single";
                        break;
                    case "release":
                        title = props.t("release_licence") + modalTarget + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=release';
                        mode = "single";
                        break;
                    case "status":
                        title = props.t("toggle_status_licence") + modalTarget + "?";
                        url = SftGlobalData.baseURL_API + 'client&action=status';
                        mode = "single";
                        break;
                    case "edit":
                        title = props.t("edit_licence") + modalTarget + "";

                        url = SftGlobalData.baseURL_API + 'client&action=edit';
                        mode = "single";
                        extraHTML = (<div>
                            <Input className={props.theme + " _100perc margin_top_10px"} placeholder='Description' value={modalValueDescr} onChange={(e)=>{setModalValueDescr(e.target.value);}}/>
                            {props.adminCheck && <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                                <p><b>{props.t("trial")}</b></p>
                                <label className="switch align_right">
                                    <input type="checkbox" checked={trial} onChange={() => {toggleTrial()}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span>
                                </label>
                            </div>}
                            {props.suppCheck && <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                                <p><b>{props.t("diskid_check")}</b></p>
                                <label className="switch align_right">
                                    <input type="checkbox" checked={diskId} onChange={() => {toggleDiskId()}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span>
                                </label>
                            </div>}
                            {props.adminCheck && <div className={props.theme + " _100perc"} style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center", paddingLeft: 10}}>
                                <p><b>{props.t("invoice")}</b></p>
                                <label className="switch align_right">
                                    <input type="checkbox" checked={invoice} onChange={() => {toggleInvoice()}}/>
                                    <span className={"slider slider_" + props.theme + " round"}></span>
                                </label>
                            </div>}

                        </div>);
                        break;
                    default:
                        title = "";
                        url = "";
                        mode = "single";
                        extraHTML = <div></div>;
                        break;
                }
                return (
                    <div>
                        <p>{title}</p>
                        {extraHTML}
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: '45%'}} onClick={()=>{licenceAPICall(url, mode); setModalOpen(false);}}>{props.t("yes")}</Button>
                            <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: '45%'}} onClick={()=>{setModalOpen(false);}}>{props.t("no")}</Button>
                        </div>
                    </div>
                );
            
            default:
                return <div></div>;

        }
    }

    const setInputRef = useCallback((element) => {
        inputExt.current = element;
        if (element) {
          element.focus();
          element.select();
        }
      }, []);

    useEffect(()=>{
        if(modalOpen && (modalAction === "extendTrial" || modalAction === "extend") && inputExt?.current !== null)
            try{
                inputExt.current.focus();
                inputExt.current.select();
            }catch(e){console.error(e);}
    }, [modalOpen]);

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <DetailPanelContent row={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 300, []);

    const gridWebhookColumns = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            disableExport: true,
            renderCell: (params) => (
              <CustomDetailPanelToggle id={params.id} value={params.value} />
            ),
        },
        {
            field: 'tags',
            headerName: '',
            width: 100,
            maxWidth: 100,
            align: 'center',
            disableExport: true,
            renderCell: (params) => {
                return (<ContentCell theme={params.row.theme} id={params.row.id} >
                        <FontAwesomeIcon icon={faSquare} color={params.row.tags.active === "1" ? "#5CB85C" : "#D9534F"} /> &nbsp;
                        <FontAwesomeIcon icon={faFile} color={params.row.tags.invoice === "1" ? Colors.sft_blue : "#DDDDDD"} />&nbsp;
                        <FontAwesomeIcon icon={faHdd} color={params.row.tags.diskid_check === "1" ? Colors.sft_blue : "#DDDDDD"} />&nbsp;
                        <FontAwesomeIcon icon={faUpload} color={params.row.tags.update === "1" ? Colors.dark_accent_darkest : "#DDDDDD"} />&nbsp;
                        <FontAwesomeIcon icon={faLock} color={params.row.tags.ssl_connect === "1" ? "#5CB85C" : "#DDDDDD"} />&nbsp;
                    </ContentCell>)
            }
        },
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 40,
            maxWidth: 40,
            width: 40,
            align: "center",
            renderCell: (params) => {
                //console.log("params", params);
                if(params.row.is_trial==="1"){
                    return  <ContentCell theme={params.row.theme} id={params.row.id} ><code className='code_trial'>{params.row.id}</code></ContentCell>;
                } else {
                    return  <ContentCell theme={params.row.theme} id={params.row.id} ><code className='code'>{params.row.id}</code></ContentCell>;
                }
            }
        },
        {
            field: 'description',
            headerName: props.t("description"),
            minWidth: 300,
            renderCell: (params) => {return <TestCell value={params.row.description} theme={params.row.theme} id={params.row.id} > </TestCell>}
        },
        {
            field: 'prog_version',
            headerName: props.t("version"),
            minWidth: 120,
            renderCell: (params) => {return <TestCell value={params.row.prog_version} theme={params.row.theme} id={params.row.id} > </TestCell>}
        },
        {
            field: 'pos_id',
            headerName: props.t("pos_id"),
            minWidth: 50,
            renderCell: (params) => {return <TestCell value={params.row.pos_id} theme={params.row.theme} id={params.row.id} > </TestCell>}
        },
        {
            field: 'valid_until',
            headerName: props.t("valid_until"),
            minWidth: 75,
            renderCell: (params) => {return <TestCell value={SftGlobalData.dateFormatter(params.row.valid_until)} theme={params.row.theme} id={params.row.id} > </TestCell>}
        },
        {
            field: 'last_used',
            headerName: props.t("last_used"),
            minWidth: 75,
            renderCell: (params) => {return <TestCell value={SftGlobalData.dateFormatter(params.row.last_used)} theme={params.row.theme} id={params.row.id} > </TestCell>}
        },
      ];

    function DataGridLicence() {
        return (
          <div style={{height: 550, width: '100%' }}>
            {
            // --------------------------------------------------------------------
            // MODAL
            // --------------------------------------------------------------------
            }
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={()=>{setModalOpen(false)}}
                BackdropComponent={Backdrop}
                >
                <Box sx={style} style={{minWidth: 400, backgroundColor: (props.theme === 'dark' ? Colors.dark_basic : Colors.light_basic)}} className={props.theme}>
                    <p className={"primary_border_bottom text_center margin_top_none margin_bottom_15px bold "} style={{fontWeight: 1200}}>{modalContent}</p>
                    {getModalContent()}
                    {(modalAction === "extend" || modalAction === "extendTrial") && <div>
                        <p>{props.t("extend_licence") + modalTarget + "?"}</p>
                        <div>
                            <p><b>{props.t("days_valid")}:</b> {props.daysValid}</p>
                            <Input inputRef={setInputRef} className={props.theme + " _100perc margin_top_10px"} placeholder='Value' value={modalValue} onChange={(e)=>{setModalValue(e.target.value);}}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: '45%'}} onClick={()=>{licenceAPICall(SftGlobalData.baseURL_API + 'client&action=extend', "single"); setModalOpen(false);}}>{props.t("yes")}</Button>
                            <Button className="modal_button_container_no_margin__button margin_top_15px" style={{width: '45%'}} onClick={()=>{setModalOpen(false);}}>{props.t("no")}</Button>
                        </div>
                    </div>}
                </Box>
            </StyledModal>
            <DataGridPro
                apiRef={dataContainer}
                className={props.theme}
                columns={gridWebhookColumns}
                rows={licences.filter(licence => (licence.id.match(new RegExp(props.searchValue, "i")) || licence.description.match(new RegExp(props.searchValue, "i")) || licence.remark.match(new RegExp(props.searchValue, "i")) || licence.warehouse.match(new RegExp(props.searchValue, "i")) || licence.pos_id.match(new RegExp(props.searchValue, "i"))))   
                .map((row) => {return {
                        tags: {update: row.update, active: row.active, invoice: row.invoice, ssl_connect: row.ssl_connect, diskid_check: row.diskid_check},
                        id: row.id,
                        description: row.description,
                        prog_version: row.prog_version,
                        pos_id: row.pos_id,
                        valid_until: row.valid_until,
                        last_used: row.last_used,
                        is_trial: row.is_trial,
                        invoice: row.invoice,
                        guid: row.guid,
                        guid_changes: row.guid_changes,
                        diskid: row.diskid,
                        diskid_check: row.diskid_check,
                        payclnt: row.payclnt,
                        payclnt_dll: row.payclnt_dll,
                        type: row.type,
                        OS_name: row.OS_name,
                        windows_version: row.windows_version,
                        remark: row.remark,
                        theme: props.theme,
                        targetModal: (content, action, id)=>{props.targetModal(content, action, id)},
                        targetModalInner: (content, action, id)=>{targetModalContent(content, action, id)},
                        resetGuidChanges: (id) => resetGuidChanges(id),
                        updateRemark: (id, remark) => updateRemark(id, remark),
                        autoUpdate: (id) => autoUpdate(id),
                        editLicenceSetup: (descr, trial, check, invoice) => licenceEditSetup(descr, trial, check, invoice),
                        adminCheck: props.adminCheck,
                        devCheck: props.devCheck,
                        suppCheck: props.suppCheck,
                        t: (translate)=>props.t(translate),
                        screenWidth: props.screenWidth
                    }
                })}
                rowThreshold={0}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                rowHeight={38}
                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LinearProgress,
                }}
                componentsProps={{ toolbar: { csvOptions: { fields: ['description', 'prog_version', 'pos_id', 'valid_until', 'last_used'], utf8WithBom: true } } }}
                loading={loading}
                pagination={false}

                />          
          </div>
        );
    }


    return (
        <div>
            {DataGridLicence()}
        </div>
    );
}

export default LicenceTableForm;

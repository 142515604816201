import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import './login.css';
import SftGlobalData from '../../library/SftGlobalData';
import LoadingButton from '@mui/lab/LoadingButton';

import logo_light from '../../assets/header/logo_light.png';
import logo_dark from '../../assets/header/logo_dark.svg';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import crypto from 'crypto-js';


const ResetPasswordScreen = (props) =>  {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordHelper, setPasswordHelper] = useState("");
    let navigate = useNavigate();
    const params = useParams();

    props.changeScreen("login");

    //---------------------------------------------------------------------------------------------
    //    FUNCTIONS
    //---------------------------------------------------------------------------------------------

    useEffect(() => {
        if(params.token){   
            let url = SftGlobalData.baseURL_API + 'user&action=fetchUserLogin';
            axios.post(url, 
                {
                    token: params.token
                }, {
                    headers : {
                        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                }).then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', url);
                        console.log('data', {token: params.token});
                        console.log('response', response);
                    }
                    if(response.data.login){
                        setUser(response.data.login);
                    } else if(response.data.error) {
                        navigate("/"+props.lang+"/login/error");
                    }
                    setLoading(false);
                }
            );   
        }
    },[params.token, props.lang]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        if(password === ''){
            props.notify(props.t('missing_fields'), 7500, "top-center", props.theme, 1);
            setLoading(false);
        }else {
            setLoading(true);
            let APIurl = SftGlobalData.baseURL_API + 'user&action=resetPassword';
            let postData = {
                login: user,
                token: params.token,
                password: crypto.MD5(JSON.stringify(password)).toString()
            };
            axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', APIurl);
                        console.log('data', postData);
                        console.log('response', response);
                        console.log('response error', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(response.data.message !== 'success'){
                            props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);  
                            setLoading(false);
                        } else {
                            navigate("/"+props.lang+"/login")
                        }
                    } else { 
                        props.notify(props.t('errorOccured'), 7500, "top-center", props.theme, 1);                     
                        setLoading(false);
                    }
                })
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function showPasswordField(){
        if(passwordHelper === ""){
            return (<FormControl sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name="txt_password" 
                className={props.theme+"_accent_darker"}
                value={checkPassword}
                onChange={(event)=>{validatePassword(event.target.value)}}
                placeholder={props.t('check_password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>) ;
        } else {
            return (<FormControl error sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name="txt_password" 
                className={props.theme+"_accent_darker"}
                value={checkPassword}
                onChange={(event)=>{validatePassword(event.target.value)}}
                placeholder={props.t('check_password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText id="component-error-text">{passwordHelper}</FormHelperText>
        </FormControl>) ;
        }
    }

    function validatePassword(secondPassword){
        if(password !== secondPassword){
            setPasswordHelper("wrong password");
            setCheckPassword(secondPassword);
        } else {
            setPasswordHelper("");            
            setCheckPassword(secondPassword);
        }
    }

    //---------------------------------------------------------------------------------------------
    //    OUTPUT
    //---------------------------------------------------------------------------------------------

    return (
        <div className='center'>
            <div className='login__'>
                <form onSubmit={handleSubmit}>
                <fieldset className={props.theme+'_accent login__form login__form_' + props.theme}>
                    <img alt='SoftTouch Logo' src={(props.theme==='dark') ? logo_dark : logo_light} className='login__logo'/>
                        <h3 className={props.theme+'_accent'}>Reset password</h3>

                        <FormControl sx={{ m: 1, width: '100%', margin: '0', marginBottom: '10px' }}>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                name="txt_password" 
                                className={props.theme+"_accent_darker"}
                                value={password}
                                onChange={(event)=>{setPassword(event.target.value)}}
                                placeholder={props.t('new_password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        
                        {showPasswordField()}
                        
                        <div className='login__buttons'>
                            <LoadingButton
                                    loading={loading}
                                    type="submit" 
                                    className='login__button'
                                    size="large"
                                    >
                                    {!loading && props.t('ok')}
                            </LoadingButton>
                        </div>
                    </fieldset>
                </form>           
            </div>
        </div>
    );
}

export default ResetPasswordScreen;
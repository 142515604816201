import '../styles/main.css';
import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faDatabase, faSignOutAlt, faMoon, faBookmark, faHeadset } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import Colors from '../library/Colors';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SftGlobalData from '../library/SftGlobalData';
import crypto from 'crypto-js';
import ServiceStatus from './ServiceStatus';

const Header = (props) =>  {
    let navigate = useNavigate();

    const logout = () => {
        props.changeScreen('login');
        navigate("/"+props.lang+"/login");
        props.toggleDatabase();
        props.emptyDbs();
        sessionStorage.removeItem('login');
        sessionStorage.removeItem('key');
        let baseLogin = {sft_group: "", sft_status:"0", sft_user_id:""};
        setCookie("cred", crypto.AES.encrypt(JSON.stringify(baseLogin), 'Soft295Touch!').toString());
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const changeDb = () => {
        props.changeScreen('environment');
        if(props.sftStatus !== "0"){
          navigate("/"+props.lang+"/env");
        } else {
          navigate("/"+props.lang+"/environment");
        }
        props.toggleDatabase();
    }

    const generateLoginCrypt = () => {
        return crypto.AES.encrypt(JSON.stringify(
            {
                login: props.login.login,
                password: props.login.password
            }
        ), "Soft295Touch!").toString();
    }

    /*const decryptTest = (test) => {
        return crypto.AES.decrypt(test, "Soft295Touch!").toString(crypto.enc.Utf8);
    }*/

    if(SftGlobalData.debug){
        console.log("login", props.login);
        console.log("screenHeader", props.screen);
    }

    const showMenuIcon = () => {
        if(props.screen !== 'environment'){
            return <MenuIcon />
        } 
    }

    const onBookmark = () => {
        if(typeof props.login !== "undefined"){
            let loginCrypt = generateLoginCrypt().replaceAll("/", "DIRTY_SLASH_FIX");
            navigator.clipboard.writeText("https://my.softtouch.eu/"+props.lang+"/override_core_protection/logging/"+loginCrypt)
            props.notify("Bookmark copied.", 2000, "top-right", props.theme, 79687); 
        }
    }
    
    return (
        <AppBar
        className={props.theme + ' header_' + props.theme}
        position="fixed"
        sx={
        props.hideSidebar ? 
        {
            width: { sm: ((props.screen === 'environment' || props.screen === 'logging') ? '100%' : `calc(100%)`)},
        }
        :
        {
          width: { sm: ((props.screen === 'environment' || props.screen === 'logging') ? '100%' : `calc(100% - ${props.drawerWidth}px)`)},
          ml: { sm: `${props.drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open"
            edge="start"
            onClick={()=>props.toggleMobileOpen()}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            {showMenuIcon()}
          </IconButton>
          
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {(props.screen !== "environment" && props.screen !== "logging") && props.dbName}
            {props.screen === "logging" && <p style={{paddingLeft: (props.screenWidth > 900 ? 65 : 0)}}>{props.t('softtouch_support_tool')}</p>}
          </Typography>
                <ServiceStatus api={props.api} />
                {(props.screenWidth > 900 && props.screen === 'logging' && ( props.login?.sft_status !== "0") && typeof props.login?.sft_status !== 'undefined' && props.sftUserId !== "") && <Button className='header__button' onClick={()=>{onBookmark();}}><FontAwesomeIcon icon={faBookmark} color={Colors.dark_text} size="2x" /></Button>}
                {(props.screenWidth > 900 && <Button className='header__button' onClick={()=>{props.toggleTheme();}}><FontAwesomeIcon icon={(props.theme==='dark') ? faLightbulb : faMoon} color={Colors.dark_text} size="2x" /></Button>)}
                {(props.screen !== 'logging' && ( props.login?.sft_status !== "0") && typeof props.login?.sft_status !== 'undefined' && props.sftUserId !== "") && <Button className='header__button' onClick={()=>{props.changeScreen('logging'); navigate("/"+props.lang+"/logging");}}><FontAwesomeIcon icon={faHeadset} color={Colors.def_red} size="2x" /></Button>}
                {(props.screen !== "environment" && (props.dbs.length > 1 || (props.sftStatus !== "0" && typeof props.login?.sft_status !== 'undefined' && props.sftUserId !== ""))) && <Button className='header__button' onClick={()=>{changeDb()}}><FontAwesomeIcon icon={faDatabase} color={Colors.dark_text} size="2x" /></Button>}
                <Button className='header__button' onClick={()=>{logout();}}><FontAwesomeIcon icon={faSignOutAlt} color={Colors.dark_text} size="2x" /></Button>    
        </Toolbar>
      </AppBar>
    );
}

export default Header;
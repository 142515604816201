export default {
    light_basic:            "#F9FAFB",
    light_accent:           "#F3F4F6",
    light_accent_darker:    "#E5E7EB",
    light_accent_darkest:   "#D1D5DB",
    light_text: 	        "#1F2937",
    light_shadow:           "#D1D5DB",

    dark_basic:             "#1B212B",
    dark_accent:            "#232B38",
    dark_accent_darker:     "#1F2937",
    dark_accent_darkest:    "#111827",
    dark_text:              "#F9FAFB",
    dark_text_accent:       "#CACBCC",
    dark_shadow:            "#111827",

    sft_blue:               "#28A9E0",
    sft_blue_text:          "#F9FAFB",

    def_green:              "#5CB85C",
    def_red:                "#D9534F",
    def_orange:             "#F0AD4E",

    def_grey:               "#C0C0C0",
}
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo_light from '../assets/header/logo_light.png';
import logo_dark from '../assets/header/logo_dark.svg';
import '../styles/main.css';
import SftGlobalData from '../library/SftGlobalData';
import Colors from '../library/Colors';
import { TailSpin } from 'react-loader-spinner';
import crypto from 'crypto-js';

const AuthLoginScreen = (props) =>  {
    const [loading, setLoading] = useState(true);
    
    const params = useParams();
    let navigate = useNavigate();

    useEffect(()=>{
        if(params.key !== ""){
            let loginCrypt = JSON.parse(crypto.AES.decrypt(params.key.replaceAll("DIRTY_SLASH_FIX", "/"), 'Soft295Touch!').toString(crypto.enc.Utf8));

            
            let APIurl = SftGlobalData.baseURL_API + 'login';
            let postData = {
                login: loginCrypt.login,
                password: loginCrypt.password
            };
            axios.post(APIurl, postData, {
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(response => {
                    if(SftGlobalData.debug){
                        console.log('url', APIurl);
                        console.log('data', postData);
                        console.log('response', response);
                        console.log('response error', typeof response.error);
                    }
                    if(typeof response.error === 'undefined'){
                        if(!response.data.is_valid){
                            setLoading(false);
                        } else {
                            props.cacheLogin(response.data); 
                            props.useDbs(response.data.dbs);
                            if(params.redirect !== ""){
                                props.changeScreen(params.redirect);
                                navigate("/"+props.lang+"/"+params.redirect);
                            } else {
                                if(response.data.dbs.length > 0 || (response.data.sft_group !== "ADMIN" && response.data.sft_group !== "SUPER")){
                                    navigate("/"+props.lang+"/environment");
                                } else {
                                    navigate("/"+props.lang+"/environment/"+response.data.environment+"*");
                                }
                            }
                        }
                    } else {                    
                        setLoading(false);
                    }
                });
        }
    }, [params.key, params.redirect, props])

    return (
        <div className='center'>
            <div className='login__'>
                <fieldset className={props.theme+'_accent login__form login__form_' + props.theme}>
                    <img alt='SoftTouch Logo' src={(props.theme==='dark') ? logo_dark : logo_light} className='login__logo'/>
                    <br/>
                    {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
                    {!loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <p>Invalid credentials.</p>
                    </div>}
                    
                        
                </fieldset>         
            </div>
        </div>
    );

}

export default AuthLoginScreen;
